import { motion } from "framer-motion";
import React, {useRef} from "react";
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
// import required modules
import { Autoplay, Pagination } from 'swiper/modules';
import './gallery.scss';

let easing = [0.6, -0.05, 0.01, 0.99];

const stagger = {
  animate: {
    transition:{
      delayChildren: 0.4,
      staggerChildren: 0.2,
      staggerDirection: 1
    }
  }
};

function Gallery() {

    const progressCircle = useRef(null);
    const progressContent = useRef(null);
    const onAutoplayTimeLeft = (s, time, progress) => {
      progressCircle.current.style.setProperty('--progress', 1 - progress);
      progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
    };

    return (
    <motion.div className="container" id="gallery" initial={{opacity: 0, height: 0}} animate={{opacity:1, height: "50vh"}} transition={{duration: 1, ease: easing}}>
        <motion.div className='gallery_container' variants={stagger}>
              <Swiper
              spaceBetween={30}
              centeredSlides={true}
              autoplay={{
              delay: 6000,
              disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={false}
              modules={[Autoplay, Pagination]}
              onAutoplayTimeLeft={onAutoplayTimeLeft}
              className="mySwiper"
              >
              <SwiperSlide><img src={process.env.PUBLIC_URL + '/images/slider/rumi_slider.jpeg'} alt="/" /></SwiperSlide>
              <SwiperSlide><img src={process.env.PUBLIC_URL + '/images/slider/rumi_slider1.png'} alt="/" /></SwiperSlide>
              <SwiperSlide><img src={process.env.PUBLIC_URL + '/images/slider/rumi_slider2.png'} alt="/" /></SwiperSlide>
              <SwiperSlide><img src={process.env.PUBLIC_URL + '/images/slider/rumi_slider3.png'} alt="/" /></SwiperSlide>
              <SwiperSlide><img src={process.env.PUBLIC_URL + '/images/slider/rumi_slider4.png'} alt="/" /></SwiperSlide>
              <SwiperSlide><img src={process.env.PUBLIC_URL + '/images/slider/rumi_slider5.png'} alt="/"/></SwiperSlide>
              <SwiperSlide><img src={process.env.PUBLIC_URL + '/images/slider/rumi_slider6.jpeg'} alt="/" /></SwiperSlide>
              <SwiperSlide><img src={process.env.PUBLIC_URL + '/images/slider/rumi_slider7.jpeg'} alt="/"/></SwiperSlide>
              <SwiperSlide><img src={process.env.PUBLIC_URL + '/images/slider/rumi_slider8.jpeg'} alt="/"/></SwiperSlide>
              <SwiperSlide><img src={process.env.PUBLIC_URL + '/images/slider/rumi_slider9.jpeg'}  alt="/"/></SwiperSlide>
              <SwiperSlide><img src={process.env.PUBLIC_URL + '/images/slider/rumi_slider10.jpeg'} alt="/"/></SwiperSlide>
              <SwiperSlide><img src={process.env.PUBLIC_URL + '/images/slider/rumi_slider11.jpeg'} alt="/" /></SwiperSlide>
              <SwiperSlide><img src={process.env.PUBLIC_URL + '/images/slider/rumi_slider12.jpeg'} alt="/" /></SwiperSlide>
              <SwiperSlide><img src={process.env.PUBLIC_URL + '/images/slider/rumi_slider13.jpeg'} alt="/" /></SwiperSlide>
              <SwiperSlide><img src={process.env.PUBLIC_URL + '/images/slider/rumi_slider14.jpeg'} alt="/"/></SwiperSlide>
              <SwiperSlide><img src={process.env.PUBLIC_URL + '/images/slider/rumi_slider15.jpeg'} alt="/" /></SwiperSlide>
              <SwiperSlide><img src={process.env.PUBLIC_URL + '/images/slider/rumi_slider16.jpeg'} alt="/"/></SwiperSlide>
              <SwiperSlide><img src={process.env.PUBLIC_URL + '/images/slider/rumi_slider17.jpeg'} alt="/"/></SwiperSlide>
              <SwiperSlide><img src={process.env.PUBLIC_URL + '/images/slider/rumi_slider18.jpeg'} alt="/"/></SwiperSlide>
              <SwiperSlide><img src={process.env.PUBLIC_URL + '/images/slider/rumi_slider19.jpeg'} alt="/"/></SwiperSlide>
              <SwiperSlide><img src={process.env.PUBLIC_URL + '/images/slider/rumi_slider21.jpeg'} alt="/" /></SwiperSlide>
              <SwiperSlide><img src={process.env.PUBLIC_URL + '/images/slider/rumi_slider22.jpeg'} alt="/"/></SwiperSlide>
              <SwiperSlide><img src={process.env.PUBLIC_URL + '/images/slider/rumi_slider23.jpeg'} alt="/" /></SwiperSlide>
              <SwiperSlide><img src={process.env.PUBLIC_URL + '/images/slider/rumi_slider24.jpeg'} alt="/"/></SwiperSlide>
              <SwiperSlide><img src={process.env.PUBLIC_URL + '/images/slider/rumi_slider25.jpeg'} alt="/" /></SwiperSlide>
              <SwiperSlide><img src={process.env.PUBLIC_URL + '/images/slider/rumi_slider26.jpeg'} alt="/" /></SwiperSlide>
              <SwiperSlide><img src={process.env.PUBLIC_URL + '/images/slider/rumi_slider27.jpeg'} alt="/"/></SwiperSlide>
              <SwiperSlide><img src={process.env.PUBLIC_URL + '/images/slider/rumi_slider28.jpeg'} alt="/" /></SwiperSlide>
              <SwiperSlide><img src={process.env.PUBLIC_URL + '/images/slider/rumi_slider29.jpeg'} alt="/" /></SwiperSlide>
              <SwiperSlide><img src={process.env.PUBLIC_URL + '/images/slider/rumi_slider30.jpeg'} alt="/" /></SwiperSlide>
              <SwiperSlide><img src={process.env.PUBLIC_URL + '/images/slider/rumi_slider31.jpeg'} alt="/" /></SwiperSlide>
              <SwiperSlide><img src={process.env.PUBLIC_URL + '/images/slider/rumi_slider32.jpeg'} alt="/"/></SwiperSlide>
              <SwiperSlide><img src={process.env.PUBLIC_URL + '/images/slider/rumi_slider33.jpeg'} alt="/" /></SwiperSlide>
              <SwiperSlide><img src={process.env.PUBLIC_URL + '/images/slider/rumi_slider34.jpeg'} alt="/" /></SwiperSlide>
                <div className="autoplay-progress" slot="container-end">
                  <svg viewBox="0 0 48 48" ref={progressCircle}>
                    <circle cx="24" cy="24" r="20"></circle>
                  </svg>
                  <span ref={progressContent}></span>
                </div>
              </Swiper>
            </motion.div>
    </motion.div>
    );
}

export default Gallery;