import { motion } from "framer-motion";
import './contactus.scss';

let easing = [0.6, -0.05, 0.01, 0.99];

const stagger = {
  animate: {
    transition:{
      delayChildren: 0.4,
      staggerChildren: 0.2,
      staggerDirection: 1
    }
  }
};

  const h1 = {
    initial: {
      top: "-20%",
      opacity: 0,
      transition: {duration: 0.05, ease: easing}
    },
    animate: {
      top:"50%",
      opacity: 1,
      transition: {
        delay: 1,
        duration: 1.0,
        ease: easing
      }
    }
  };

function ContactUs() {
    return (
    <motion.div className="contact_container" id="contact" initial={{opacity: 0, height: 0}} animate={{opacity:1, height: "auto"}} transition={{duration: 1, ease: easing}}>
        <motion.div className='wrapper' initial='initial' animate='animate'>
            <motion.div className='contact_info' variants={stagger}>
              <motion.h1 variants={h1}>Contact Us</motion.h1>
              <motion.div className='info_container'>
                <motion.h2 variants={h1}>Authorized by</motion.h2>
                <motion.p variants={h1}>Shahil Memon</motion.p>
                <motion.h2 variants={h1}>Address</motion.h2>
                <motion.p variants={h1}>408/A, Shivalik - 5, Mahalakshmi Cross Road, Paldi, Ahmedabad-380007</motion.p>
                <motion.h2 variants={h1}>Contact No.</motion.h2>
                <motion.p variants={h1}>+91 9824236555</motion.p>
                <motion.h2 variants={h1}>GST No.</motion.h2>
                <motion.p variants={h1}>24ABSFA1137D1ZS</motion.p>
              </motion.div>
            </motion.div>
        </motion.div>
    </motion.div>
    );
}

export default ContactUs;