import React from 'react';
import logo from '../../../rumi_logo.svg';
import './loadingscreen.scss'
const LoadingScreen = () => {
  return (
    <div className="loading-screen">
      <img src={logo} className="App-logo" alt="logo" />
    </div>
  );
};

export default LoadingScreen;