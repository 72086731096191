import React, { useRef } from 'react'
import { FaBars, FaTimes } from 'react-icons/fa'
import { Link } from 'react-scroll';

import './Navbar.css'

const Navbar = () => {
    const navRef = useRef();

	const showNavbar = () => {
		navRef.current.classList.toggle(
			"responsive_nav"
		);
	};

	return (
		<header>
			<h3><Link to="hero" duration={500} spy={true} smooth={true} offset={-150}><h2>RUMI</h2></Link></h3>
			<nav ref={navRef}>
            <Link to="gallery" duration={500} spy={true} smooth={true} offset={0} className="nav-link" onClick={showNavbar}>Gallery</Link>
            <Link to="rumi_event" duration={500} spy={true} smooth={true} offset={0} className="nav-link" onClick={showNavbar}>Events</Link>
            <Link to="products" duration={500} spy={true} smooth={true} offset={0} className="nav-link" onClick={showNavbar}>Products</Link>
            <Link to="about" duration={500} spy={true} smooth={true} offset={0} className="nav-link" onClick={showNavbar}>About Us</Link>
            <Link to="contact" duration={500} spy={true} smooth={true} offset={0} className="nav-link" onClick={showNavbar}>Contact Us</Link>
				<button
					className="nav-btn nav-close-btn"
					onClick={showNavbar}>
					<FaTimes size={42}/>
				</button>
			</nav>
			<button
				className="nav-btn"
				onClick={showNavbar}>
				<FaBars size={42}/>
			</button>
		</header>
	);
/* 
    const [click, setClick] = useState(false)
    const handleClick = () => setClick(!click)

    const closeMenu = () => setClick(false)

    return (
        <div className='header'>
            <nav className='navbar'>
                <Link to="hero" duration={500} spy={true} smooth={true} offset={-150} onClick={closeMenu}><h2>RUMI</h2></Link>
                <div className='hamburger' onClick={handleClick}>
                    {click ? (<FaTimes size={30} style={{ color: '#ffffff' }} />)
                        : (<FaBars size={30} style={{ color: '#ffffff' }} />)}

                </div>
                <ul className={click ? "nav-menu active" : "nav-menu"}>
                    <li className='nav-item'>
                        <Link to="gallery" duration={500} spy={true} smooth={true} offset={-100} onClick={closeMenu}>Gallery</Link>
                    </li>
                    <li className='nav-item'>
                        <Link to="products" duration={500} spy={true} smooth={true} offset={-100} onClick={closeMenu}>Products</Link>
                    </li>
                    <li className='nav-item'>
                    <Link to="about" duration={500} spy={true} smooth={true} offset={-100} onClick={closeMenu}>About Us</Link>
                    </li>
                    <li className='nav-item'>
                    <Link to="contact" duration={500} spy={true} smooth={true} offset={-150} onClick={closeMenu}>Contact Us</Link>
                    </li>
                </ul>
            </nav>
        </div>
    ) */
}

export default Navbar
