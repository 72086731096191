import { motion } from "framer-motion";
import './products.scss';

let easing = [0.6, -0.05, 0.01, 0.99];

const stagger = {
  animate: {
    transition:{
      delayChildren: 0.4,
      staggerChildren: 0.2,
      staggerDirection: 1
    }
  }
};

function Products() {
    return (
    <motion.div className="container" id="products" initial={{opacity: 0, height: 0}} animate={{opacity:1, height: "auto"}} transition={{duration: 1, ease: easing}}>
        <motion.div className='wrapper' initial='initial' animate='animate'>
            <motion.div className='product_main_container' variants={stagger}>
              <motion.div className='product_card_container' whileInView={{ x: 0 }} initial={{ x: '100%' }} transition={{ duration: 1 }}>
                <motion.div className='product_card' whileHover={{ scale: 1.1 }} transition={{ duration: 0.2 }}>
                  <div className='product_image'>
                    <img src={process.env.PUBLIC_URL + `/images/rumi_black.png`} alt='Product 1' />
                  </div>
                  <div className='product_info'>
                    <h2>Rumi Can</h2>
                  </div>
                </motion.div>
                <motion.div className='product_card' whileHover={{ scale: 1.1 }} transition={{ duration: 0.2 }}>
                  <div className='product_image'>
                    <img src={process.env.PUBLIC_URL + `/images/rumi_bottle.png`} alt='Product 1' />
                  </div>
                  <div className='product_info'>
                    <h2>Rumi Bottle</h2>
                  </div>
                </motion.div>
              </motion.div>
            </motion.div>
        </motion.div>
    </motion.div>
    );
}

export default Products;