import { motion } from "framer-motion";
import './rumievents.scss';

let easing = [0.6, -0.05, 0.01, 0.99];

const stagger = {
  animate: {
    transition:{
      delayChildren: 0.4,
      staggerChildren: 0.2,
      staggerDirection: 1
    }
  }
};

  const h1 = {
    initial: {
      top: "-20%",
      opacity: 0,
      transition: {duration: 0.05, ease: easing}
    },
    animate: {
      top:"50%",
      opacity: 1,
      transition: {
        delay: 1,
        duration: 1.0,
        ease: easing
      }
    }
  };

function RumiEvents() {
    return (
    <motion.div className="event_container" id="rumi_event" initial={{opacity: 0, height: 0}} animate={{opacity:1, height: "100vh"}} transition={{duration: 1, ease: easing}}>
        <motion.div className='rumi_event_info' variants={stagger}>
            <motion.h1 variants={h1}>Events Coming Soon...</motion.h1>
        </motion.div>
    </motion.div>
    );
}

export default RumiEvents;