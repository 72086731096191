import Home from "./components/screens/home/home";
import AboutUs from "./components/screens/aboutus/aboutUs";
import Gallery from "./components/screens/gallery/gallery";
import RumiEvents from "./components/screens/rumiEvents/rumiEvents";
import Products from "./components/screens/products/products";
import ContactUs from "./components/screens/contactus/contactUs";
import { useEffect, useState } from "react";
import LoadingScreen from "./components/screens/loading/loadingScreen";
import Navbar from './components/NavBar/Navbar'
import Video from './images/rumi_vid.mp4';
import './app.scss'

function App() {

  const [loading, setLoading] = useState(true)
    useEffect(() => {
        setTimeout(() => setLoading(false), 3300)
    }, [])
    if (loading) {
        return <LoadingScreen/>
    }

  return (
    <div className="app">
      <Navbar />
      <Home />
      <div className="video-container">
      <video autoPlay loop muted>
        <source src={Video} type="video/mp4" />
      </video>
      </div>
      <Gallery />
      <RumiEvents />
      <Products />
      <AboutUs className="aboutus"/>
      <ContactUs />
    </div>
  );
}

export default App;
